<template>
  <div id="history-screen" translate="no">
    <Loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :height="40"
      loader="spinner"
      color="#003340"
    ></Loading>
    <BackToServices
      :table="tableId"
      :storeName="storeName"
      :isApplication="isApplication"
      :hasBoxShadow="true"
      :amountOfDepartments="2"
      :restaurantType="'HISTORY'"
      id="back-to-services"
    ></BackToServices>
    <div id="banner">
      <img v-if="store" id="banner-image" :src="`${store.bannerLink}`" alt />

      <div id="store-info">
        <Store id="store" :store="store" :shouldShowMenu="false"></Store>
      </div>

      <div id="upper-bar">
        <div id="logo-container" v-if="!isApplication">
          <a id="link-to-landing" href="/">
            <img id="logo" src="../../assets/Full_logo-Black.svg" alt />
          </a>
        </div>
        <div id="profile-and-cart">
          <LanguageSelect
            id="language-select"
            :class="{ 'language-position-in-login': isLoggedIn }"
          ></LanguageSelect>

          <ProfileDropdown
            v-if="isLoggedIn"
            id="profile-dropdown"
            :isApplication="isApplication"
            :class="{ 'profile-dropdown-with-lang-select': isAbleToChangeLanguage,
                    'profile-dropdown-without-lang-select': !isAbleToChangeLanguage }"
          ></ProfileDropdown>
          <EnterPrompt
            v-else-if="shouldShowEnterPrompt && !isLoggedIn && !isApplication"
            @enter-clicked="initiateEntrance"
            id="enter-prompt"
            :class="{ 'enter-prompt-with-lang-select': isAbleToChangeLanguage,
                    'enter-prompt-without-lang-select': !isAbleToChangeLanguage }"
          ></EnterPrompt>
        </div>
      </div>
    </div>
    <div
      id="history-results-container"
      v-if="isLoggedIn && waiterOfcurrentUser!=null && waiterOfcurrentUser.ableToSeeMobileReports && !isLoading"
    >
      <div id="select-filter-container">
        <div id="type-of-reports-container">
          <button
            @click="setTypeOfReport('ACCOUNTS')"
            class="report-type-selection-button"
            :class="{ 'selected-report-type': typeOfReport=='ACCOUNTS'}"
          >Λογαριασμοί</button>
          <button
            @click="setTypeOfReport('STATS')"
            class="report-type-selection-button"
            :class="{ 'selected-report-type': typeOfReport=='STATS'}"
          >Στατιστικά</button>
        </div>

        <div class="label-and-name" v-if="restaurantsWithTableOpenings.length>1">
          <div class="label">
            <h6 class="filter-title">{{labels.tableOpenings.department[language]}}</h6>
            <select style="width:167px" v-model="selectedDepartmentId">
              <option v-if="typeOfReport!='STATS'" :value="null">Όλα</option>
              <option
                v-for="restaurant in restaurantsWithTableOpenings"
                :key="restaurant.id"
                :value="restaurant.id"
              >{{restaurant.names.translation[language].name }}</option>
            </select>
          </div>
        </div>

        <div
          v-if="typeOfReport=='ACCOUNTS'"
          class="label-and-name"
          style="margin-top: 2px !important"
        >
          <div class="label" style="margin-right:10px">
            <h6
              class="filter-title no-bottom-margin"
            >{{labels.tableOpenings.typeOfTable[language]}}:</h6>
            <select style="width:214px" class="no-bottom-margin" v-model="tableType">
              <option value="ALL">Όλα</option>
              <option value="TABLES">{{labels.table.tables[this.language]}}</option>
              <option value="ROOMS">{{labels.table.rooms[this.language]}}</option>
              <option value="DELIVERY">Delivery</option>
              <option value="TAKE_AWAY">Takeaway | Self service</option>
            </select>
          </div>
        </div>

        <div v-if="typeOfReport=='ACCOUNTS'" class="label-and-name">
          <div class="label" style="margin-right:10px">
            <h6 class="filter-title no-bottom-margin">Χρήστης:</h6>
            <select style="width:190px" class="no-bottom-margin" v-model="waiterToSearch">
              <option :value="null">Όλοι</option>
              <option
                v-for="waiter in waiters"
                :key="waiter.userId"
                :value="waiter.userId"
              >{{waiter.name }}</option>
            </select>
          </div>
        </div>

        <div
          class="label-and-name"
          :class="{ 'margin-top-0': typeOfReport=='STATS', 'margin-top-16': typeOfReport=='ACCOUNTS'}"
        >
          <div class="label" style="margin-right:10px">
            <h6 class="filter-title no-bottom-margin" style="margin-right:6px">Από</h6>
            <date-picker
              @change="clearErrors()"
              @pick="clearErrors()"
              style="width:230px"
              type="datetime"
              v-model="startingDate"
              :time-picker-options="timePickerOptions"
              time-title-format="DD MMM YYYY"
              format="ddd DD MMM YYYY | HH:mm"
              placeholder="Select date and time"
              :show-second="false"
            ></date-picker>
          </div>
        </div>
        <div class="label-and-name">
          <div class="label" style="margin-right:10px">
            <h6 class="filter-title no-bottom-margin" style="margin-right:6px">Εώς</h6>
            <date-picker
              @change="clearErrors()"
              @pick="clearErrors()"
              style="width:230px"
              type="datetime"
              v-model="endingDate"
              :time-picker-options="timePickerOptions"
              time-title-format="DD MMM YYYY"
              format="ddd DD MMM YYYY | HH:mm"
              placeholder="Select date and time"
              :show-second="false"
            ></date-picker>
          </div>
        </div>
        <div id="actions-buttons-container">
          <button id="search-history-btn" @click="getReports()">Αναζήτηση</button>
        </div>
        <p v-if="error" id="error-message">{{errorMessage}}</p>
      </div>

      <div v-if="typeOfReport=='ACCOUNTS' && !searching" id="prices-results-container">
        <h6
          id="amount-of-closed"
        >{{totalAmountOfClosed}} {{totalAmountOfClosed==1?'Λογαριασμός': 'Λογαριασμοί'}}</h6>
        <h6
          class="total-price-of-closed"
        >Σύνολο: {{pricesOfClosed !=null && pricesOfClosed.totalPrice!=null ? pricesOfClosed.totalPrice.toFixed(2): '0.00'}}€</h6>
        <h6 class="payment-method-price">
          <font-awesome-icon style="color: #198b4a" icon="money-bill" />
          Μετρητά: {{pricesOfClosed !=null && pricesOfClosed.amountPayedByCash!=null ? pricesOfClosed.amountPayedByCash.toFixed(2) : '0.00'}}€
        </h6>
        <h6 class="payment-method-price">
          <font-awesome-icon style="color: #17a2b8" icon="credit-card" />
          Κάρτα: {{pricesOfClosed !=null && pricesOfClosed.amountPayedByCard!=null ? pricesOfClosed.amountPayedByCard.toFixed(2) : '0.00'}}€
        </h6>
        <h6 v-if="store && store.hotel" class="payment-method-price">
          <font-awesome-icon style="color: #003340" icon="bed" />
          Room charge: {{pricesOfClosed !=null && pricesOfClosed.amountChargedToRoom!=null ? pricesOfClosed.amountChargedToRoom.toFixed(2) : '0.00'}}€
        </h6>
        <h6 class="payment-method-price">
          <font-awesome-icon style="color: #efb400" icon="history" />
          Επί πιστώσει: {{pricesOfClosed !=null && pricesOfClosed.amountOnCredit!=null ? pricesOfClosed.amountOnCredit.toFixed(2) : '0.00'}}€
        </h6>
        <h6 class="payment-method-price">
          <font-awesome-icon style="color: #6f2c91" icon="gift" />
          Κερασμένα: {{pricesOfClosed !=null && pricesOfClosed.amountTreated!=null ? pricesOfClosed.amountTreated.toFixed(2) : '0.00'}}€
        </h6>
        <h6 class="payment-method-price">
          <font-awesome-icon style="color: #d9534f" icon="trash" />
          Ακυρωμένα: {{pricesOfClosed !=null && pricesOfClosed.amountCanceled!=null ? pricesOfClosed.amountCanceled.toFixed(2) : '0.00'}}€
        </h6>
      </div>

      <div
        v-if="typeOfReport=='ACCOUNTS' && isAtLeastOneRestaurantPublishesReceipt && !searching"
        id="receipts-results-container"
      >
        <h6 id="amount-of-closed">
          <font-awesome-icon style="color: #003340; margin-right:6px" icon="receipt" />Αποδείξεις
        </h6>
        <h6
          class="total-price-of-closed"
        >Σύνολο: {{getTotalPriceForReceiptsFixed()}}€ | ΦΠΑ: {{pricesOfClosed !=null && pricesOfClosed.amountOfVat!=null ? pricesOfClosed.amountOfVat.toFixed(2) : '0.00'}}€</h6>
        <h6 class="payment-method-price">
          <font-awesome-icon style="color: #198b4a" icon="money-bill" />
          Μετρητά: {{pricesOfClosed !=null && pricesOfClosed.amountOfCashReceipts!=null ? pricesOfClosed.amountOfCashReceipts.toFixed(2) : '0.00'}}€
        </h6>
        <h6 class="payment-method-price">
          <font-awesome-icon style="color: #17a2b8" icon="credit-card" />
          Κάρτα: {{pricesOfClosed !=null && pricesOfClosed.amountOfCardReceipts!=null ? pricesOfClosed.amountOfCardReceipts.toFixed(2) : '0.00'}}€
        </h6>
        <h6 class="payment-method-price">
          <font-awesome-icon style="color: #efb400" icon="history" />
          Επί πιστώσει: {{pricesOfClosed !=null && pricesOfClosed.amountOfOnCreditReceipts!=null ? pricesOfClosed.amountOfOnCreditReceipts.toFixed(2) : '0.00'}}€
        </h6>
        <h6 class="payment-method-price">
          <font-awesome-icon style="color: #6f2c91" icon="gift" />
          Αυτοπαραδόσεις: {{pricesOfClosed !=null && pricesOfClosed.amountOfSelfDeliveryReceipts!=null ? pricesOfClosed.amountOfSelfDeliveryReceipts.toFixed(2) : '0.00'}}€
        </h6>
      </div>

      <div v-if="typeOfReport=='STATS' && !searching" id="statistics-results-container">
        <h6 class="info-message" style="padding-left:10px" v-if="itemStats.length == 0">
          <font-awesome-icon style="color: #003340; margin-right:4px" icon="info-circle" />Δεv υπάρχουν στατιστικά πωλήσεων για τα κριτήρια που έχετε επιλέξει
        </h6>

        <div
          v-if="itemStats.length>0"
          class="label-and-name"
          style="margin-top: 14px !important; margin-bottom: 4px !important"
        >
          <div class="label" style="margin-right:10px">
            <h6 class="filter-title">Κατηγορία:</h6>
            <select style="width:167px" v-model="selectedCategory">
              <option value="0">Όλες</option>
              <option
                v-for="category in uniqueCategories"
                :key="category.id"
                :value="category.id"
              >{{ getCategoryName(category) }}</option>
            </select>
          </div>
        </div>

        <table v-if="itemStats.length>0" id="statistics-results-container">
          <tr>
            <th class="product-header">Προϊόν</th>
            <th class="text-center">Τμχ</th>
            <th class="text-center">Αξία</th>
            <th class="text-center">Κατηγορία</th>
          </tr>
          <tr :key="item.id" v-for="item in itemsToShow">
            <td class="product-header">{{ getProductName(item) }}</td>
            <td class="text-center">{{item.quantity}}</td>
            <td class="text-center">{{ item.totalPrice }}€</td>
            <td class="text-center">{{ getProductCategoryName(item) }}</td>
          </tr>
        </table>
      </div>

      <clip-loader v-if="searching" :loading="searching" size="30px" color="#003340" />
    </div>
    <div
      id="general-errors-container"
      v-if="(!isLoggedIn || waiterOfcurrentUser==null || !waiterOfcurrentUser.ableToSeeMobileReports) && !isLoading"
    >
      <h6 class="info-message" v-if="!isLoggedIn">
        <font-awesome-icon style="color: #003340; margin-right:4px" icon="info-circle" />Για να δείτε τα έσοδα πρέπει να συνδεθείτε. Πηγαίντε πίσω και κάντε είσοδο πάνω δεξιά με το pin σας. Ξαναμπείτε μετά εδώ ώστε να δείτε τα έσοδα του καταστήματος
      </h6>
      <h6
        class="info-message"
        v-if="isLoggedIn && (waiterOfcurrentUser==null || !waiterOfcurrentUser.ableToSeeMobileReports)"
      >
        <font-awesome-icon style="color: #003340; margin-right:4px" icon="info-circle" />Δεν έχετε δικαιώματα προβολής εσόδων του καταστήματος
      </h6>
    </div>
    <div
      @click="onMaskClick"
      :class="{
        mask:
          isEntranceOpen ||
          isLanguageModalOpen
      }"
    ></div>
    <PoweredByFooter v-if="!isLoading"></PoweredByFooter>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import Store from "../Store";
import StoreApi from "../../api/stores";
import ProfileDropdown from "../ProfileDropdown";
import EnterPrompt from "../EnterPrompt";
import LanguageSelect from "../LanguageSelect";
import BackToServices from "../BackToServices.vue";
import OrdersOfOpenTableModal from "../OrdersOfOpenTableModal";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import _ from "lodash";

import PoweredByFooter from "../PoweredByFooter.vue";

export default {
  name: "TablesScreen",
  components: {
    Store,
    ProfileDropdown,
    EnterPrompt,
    Loading,
    LanguageSelect,
    BackToServices,
    ClipLoader,
    OrdersOfOpenTableModal,
    PoweredByFooter,
    DatePicker
  },
  props: [""],
  data() {
    return {
      store: undefined,
      isLoading: true,
      from: undefined,
      fromRouteName: '',
      shouldShowEnterPrompt: false,
      isApplication: false,
      isAbleToChangeLanguage: true,
      restaurantsWithTableOpenings: [],
      tableType: "ALL",
      selectedDepartmentId: null,
      hotelRooms: [],
      tables: [],
      waiters: [],
      waiterOfcurrentUser: null,
      startingDate: null,
      endingDate: null,
      pricesOfClosed: null,
      totalAmountOfClosed: 0,
      isAtLeastOneRestaurantPublishesReceipt: false,
      error: false,
      errorMessage: '',
      waiterToSearch: null,
      searching: true,
      typeOfReport: "ACCOUNTS",
      itemStats: [],
      selectedCategory: 0
    };
  },
  watch: {
    isTableOrdersModalOpen: function (newVal, oldVal) {
      let htmlElement = document.querySelector("html");
      if (newVal == true) {
        htmlElement.style.overflow = "hidden";
      } else {
        htmlElement.style.overflow = "auto";
      }
    }
  },
  async mounted() {
    this.isLoading = true;
    this.selectToday();
    let storeId = this.$router.currentRoute.params.storeId;
    let tableId = this.$router.currentRoute.params.tableId;
    if (this.$route.params.store) {
      this.store = this.$route.params.store;
    } else if (!this.store) {
      this.store = await StoreApi.getStoreById(storeId);
    }
    this.$store.dispatch("setTableId", tableId)

    let serviceName = {
      GRE: {
        name: "Έσοδα"
      },
      ENG: {
        name: "Earnings"
      },
    };
    this.$store.dispatch("setServiceName", serviceName);
    this.setTitle();

    this.isLoading = false;
    if (this.isLoggedIn) {
      await this.loadRequiredStuffForFilters();
      if (this.waiterOfcurrentUser != null && this.waiterOfcurrentUser.ableToSeeMobileReports) {
        this.searching = true;
        this.restaurantsWithTableOpenings = await StoreApi.getRestaurantsWithTableOpenings(this.store.id, this.authToken, this.jwt);
        await this.getReports();
      }
    }

  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromRouteName = from.name;
      vm.from = from;
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEntranceOpen) {
      this.closeEntrance();
      //below is needed for same reason as above
      window.history.scrollRestoration = "manual";
      next(false);
    } else if (this.isLanguageModalOpen) {
      this.$store.dispatch("closeLanguageModal");
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else if (this.isTableOrdersModalOpen) {
      try {
        this.$refs.ordersOfOpenTableComponent.setResetOfStateOnOrdersChange(true);
      } catch (error) {
        console.log("Could not stop reset of state on orders change");
      }
      this.$store.dispatch("closeTableOrdersModal");
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else {
      next();
    }
  },
  beforeDestroy() {

  },
  computed: {
    tableId() {
      return this.$store.getters.getTableId;
    },

    isEntranceOpen() {
      return this.$store.getters.getSlideEntranceIn;
    },

    isTableOrdersModalOpen() {
      return this.$store.state.tableOrdersModalOpen;
    },

    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },

    isLanguageModalOpen() {
      return this.$store.getters.isLanguageModalOpen;
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    currentGreekDate() {
      return this.$store.getters.getCurrentGreekDate;
    },

    authToken() {
      return this.$store.getters.getUserAuthToken;
    },

    jwt() {
      return this.$store.getters.getUserJWT;
    },

    currentUserId() {
      return this.$store.getters.getUserId;
    },

    storeName() {
      if (this.store) {
        return this.store.names && this.store.names.translation[this.language]
          ? this.store.names.translation[this.language].name
          : this.store.name;
      }
    },

    title() {
      return `Store history | Fluter`;
    },

    uniqueCategories() {
      if (this.itemStats == null || this.itemStats.length == 0) {
        return;
      } else {
        let uniqueCategories = [];

        this.itemStats.forEach((item) => {
          uniqueCategories.push(item.productCategory);
        });

        const unique = [
          ...new Map(
            uniqueCategories.map((item) => [item["id"], item])
          ).values(),
        ];

        return unique;
      }
    },

    itemsToShow() {
      if (this.itemStats == null || this.itemStats.length == 0) {
        return [];
      }

      if (this.selectedCategory == 0) {
        return this.itemStats;
      }

      let items = [];

      this.itemStats.forEach((item) => {
        if (item.productCategory.id == this.selectedCategory) {
          items.push(item);
        }
      });

      return items;
    },

    timePickerOptions() {
      return {
        start: '00:00',
        step: `00:15`,
        end: '23:59',
        format: 'HH:mm'
      };
    },

  },

  methods: {

    getCategoryName(category) {
      return category.categoryName.translation.GRE
        ? category.categoryName.translation.GRE.name
        : category.categoryName.translation.ENG
          ? category.categoryName.translation.ENG.name
          : "";
    },

    getProductName(item) {
      return item.productName.translation.GRE
        ? item.productName.translation.GRE.name
        : item.productName.translation.ENG
          ? item.productName.translation.ENG.name
          : "";
    },

    getProductCategoryName(item) {
      return item.productCategory.categoryName.translation.GRE
        ? item.productCategory.categoryName.translation.GRE.name
        : item.productCategory.categoryName.translation.ENG
          ? item.productCategory.categoryName.translation.ENG.name
          : "";
    },

    getTotalPriceForReceiptsFixed() {
      let amountOfCashReceipts = this.pricesOfClosed != null && this.pricesOfClosed.amountOfCashReceipts != null ? this.pricesOfClosed.amountOfCashReceipts : 0;
      let amountOfCardReceipts = this.pricesOfClosed != null && this.pricesOfClosed.amountOfCardReceipts != null ? this.pricesOfClosed.amountOfCardReceipts : 0;
      let amountOfOnCreditReceipts = this.pricesOfClosed != null && this.pricesOfClosed.amountOfOnCreditReceipts != null ? this.pricesOfClosed.amountOfOnCreditReceipts : 0;
      let amountOfSelfDeliveryReceipts = this.pricesOfClosed != null && this.pricesOfClosed.amountOfSelfDeliveryReceipts != null ? this.pricesOfClosed.amountOfSelfDeliveryReceipts : 0;

      return (amountOfCardReceipts + amountOfCashReceipts + amountOfOnCreditReceipts + amountOfSelfDeliveryReceipts).toFixed(2);
    },

    onMaskClick: function () {
      if (this.isEntranceOpen) {
        this.closeEntrance();
      } else if (this.isLanguageModalOpen) {
        this.$store.dispatch("closeLanguageModal");
      }
    },

    clearErrors() {
      this.error = false;
      this.errorMessage = '';
    },

    closeEntrance: function () {
      this.$store.dispatch("shouldSlideEntranceOut");

      this.$store.dispatch("shouldNotSlideEntranceIn");

      setTimeout(() => {
        this.$store.dispatch("resetEntranceState");
        this.$store.dispatch("setEntranceMessage", "");

        this.$store.dispatch("resetVerificationState");
        this.$store.dispatch("setVerificationMessage", "");
      }, 850);
    },

    initiateEntrance: function () {
      this.$store.dispatch("shouldSlideEntranceIn");
    },

    formatDateForServer(date) {
      let yyyy = date.getFullYear();
      let MM = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      let dd = String(date.getDate()).padStart(2, '0');
      let HH = String(date.getHours()).padStart(2, '0');
      let mm = String(date.getMinutes()).padStart(2, '0');
      let ss = String(date.getSeconds()).padStart(2, '0');

      return `${yyyy}-${MM}-${dd} ${HH}:${mm}:${ss}`;
    },

    async setTypeOfReport(typeOfReport) {
      this.typeOfReport = typeOfReport;
      if (typeOfReport == 'STATS' && this.selectedDepartmentId == null) {
        this.selectedDepartmentId = this.restaurantsWithTableOpenings[0].id;
      } else if (typeOfReport == 'ACCOUNTS') {
        this.selectedDepartmentId = null;
      }

      if (this.typeOfReport == 'ACCOUNTS') {
        await this.getHistoryOfAccounts();
      } else if (this.typeOfReport == 'STATS') {
        this.selectedCategory = 0;
        await this.getProductSales();
      }
    },

    selectToday() {
      let now = new Date();
      let hours = now.getHours();
      let minutes = now.getMinutes();
      let month = now.getMonth() + 1;
      let date = now.getDate();

      if (hours < 5 || (hours === 5 && minutes < 30)) {
        if (date == 1) {
          let previousMonth = month == 1 ? 12 : month - 1;
          let yearForStartingDate = previousMonth == 12 ? now.getFullYear() - 1 : now.getFullYear();
          let startingDay = this.getLastDayOfMonth(previousMonth, yearForStartingDate);
          startingDay = startingDay < 10 ? `0${startingDay}` : startingDay;
          previousMonth = previousMonth < 10 ? `0${previousMonth}` : previousMonth;
          this.startingDate = new Date(`${yearForStartingDate}-${previousMonth}-${startingDay}T05:30:00`);

          let yearForEndingDate = now.getFullYear();
          let startingDayForEndingDate = date < 10 ? `0${date}` : date;
          let endingMonth = month < 10 ? `0${month}` : month;
          this.endingDate = new Date(`${yearForEndingDate}-${endingMonth}-${startingDayForEndingDate}T05:30:00`);

        } else {
          let startingDay = date - 1;
          let year = now.getFullYear();
          startingDay = startingDay < 10 ? `0${startingDay}` : startingDay;
          month = month < 10 ? `0${month}` : month;
          this.startingDate = new Date(`${year}-${month}-${startingDay}T05:30:00`);

          date = date < 10 ? `0${date}` : date;
          this.endingDate = new Date(`${year}-${month}-${date}T05:30:00`);

        }
      } else {
        let year = now.getFullYear();
        let lastDayOfMonth = this.getLastDayOfMonth(month, year);
        if (date == lastDayOfMonth) {
          let startingDay = date;
          let year = now.getFullYear();
          startingDay = startingDay < 10 ? `0${startingDay}` : startingDay;
          let startingMonth = month < 10 ? `0${month}` : month;
          this.startingDate = new Date(`${year}-${startingMonth}-${startingDay}T05:30:00`);

          let endingDay = '01'
          let endingMonth = month + 1 == 13 ? 1 : month + 1;
          let endingYear = endingMonth == 1 ? year + 1 : year;
          endingMonth = endingMonth < 10 ? `0${endingMonth}` : endingMonth;
          this.endingDate = new Date(`${endingYear}-${endingMonth}-${endingDay}T05:30:00`);
        } else {
          let startingDay = date;
          let year = now.getFullYear();
          startingDay = startingDay < 10 ? `0${startingDay}` : startingDay;
          let startingMonth = month < 10 ? `0${month}` : month;
          this.startingDate = new Date(`${year}-${startingMonth}-${startingDay}T05:30:00`);

          let endingDay = date + 1;
          endingDay = endingDay < 10 ? `0${endingDay}` : endingDay;
          let endingMonth = month < 10 ? `0${month}` : month;
          this.endingDate = new Date(`${year}-${endingMonth}-${endingDay}T05:30:00`);
        }
      }
    },

    getLastDayOfMonth(month, year) {
      if (month == 2) {
        let lastDay = year % 4 == 0 ? 29 : 28;
        return lastDay;
      } else {
        let monthsWith30Days = [4, 6, 9, 11];
        let lastDay = monthsWith30Days.includes(month) ? 30 : 31;
        return lastDay;
      }
    },

    scrollDownPixels(pixels) {
      try {
        window.scrollTo(0, window.scrollY + pixels);
      } catch (error) {
        console.log("Could not scroll");
      }
    },

    findServiceName(userId) {
      let waiter = this.waiters.find(waiter => waiter.userId == userId);
      if (waiter != null) {
        return waiter.name;
      }
      return "-";
    },

    async loadRequiredStuffForFilters() {
      this.isLoading = true;

      this.restaurantsWithTableOpenings = await StoreApi.getRestaurantsWithTableOpenings(this.store.id, this.authToken, this.jwt);
      let restaurantIds = this.restaurantsWithTableOpenings.map(restaurant => restaurant.id);

      if (this.store.hotel && this.hotelRooms.length == 0) {
        let apiResponse = await Promise.all([StoreApi.getListOfRooms(this.store.id, false, null, false), StoreApi.getAllTablesOfStore(this.store.id), StoreApi.fetchWaiters(restaurantIds, this.authToken, this.jwt)]).then(data => {
          return {
            rooms: data[0],
            tables: data[1],
            waiters: data[2]
          }
        });
        this.hotelRooms = apiResponse.rooms;
        this.tables = apiResponse.tables;
        this.waiters = apiResponse.waiters;
      } else {
        let apiResponse = await Promise.all([StoreApi.getAllTablesOfStore(this.store.id), StoreApi.fetchWaiters(restaurantIds, this.authToken, this.jwt)]).then(data => {
          return {
            tables: data[0],
            waiters: data[1]
          }
        });

        this.tables = apiResponse.tables;
        this.waiters = apiResponse.waiters;
      }

      this.waiterOfcurrentUser = this.waiters.find(waiter => waiter.userId == this.currentUserId);
      this.isAtLeastOneRestaurantPublishesReceipt = this.restaurantsWithTableOpenings.some(restaurantWithOpening => restaurantWithOpening.publishesReceipts);
      this.selectedDepartmentId = null
      this.isLoading = false;

    },

    getTablesToSearch() {
      let tablesToSearch = [];
      if (this.tableType == 'ALL') {
        tablesToSearch = this.tables.concat(this.hotelRooms);
      } else if (this.tableType == 'TABLES') {
        tablesToSearch = this.tables.filter(table => table.type == 'TABLE' || table.type == 'BEACH_CHAIR' || table.type == 'TABLE_AT_HOTEL' || table.type == 'UMBRELLA_AT_HOTEL');
      } else if (this.tableType == 'ROOMS') {
        tablesToSearch = this.hotelRooms;
      } else if (this.tableType == 'DELIVERY') {
        tablesToSearch = this.tables.filter(table => table.type == 'DELIVERY' || table.serviceMode == 'DELIVERY');
      } else {
        tablesToSearch = this.tables.filter(table => table.type == 'QR_SELF_SERVICE' || table.type == 'SELF_SERVICE_POS' || table.serviceMode == 'TAKEAWAY');
      }
      return tablesToSearch;
    },

    async getReports() {
      this.error = false;
      this.errorMessage = '';

      if (this.startingDate == null) {
        this.error = true;
        this.errorMessage = 'Επιλέξτε ημερομηνία αρχής έυρους αναζήτησης';
        return;
      }

      if (this.endingDate == null) {
        this.error = true;
        this.errorMessage = 'Επιλέξτε ημερομηνία τέλους έυρους αναζήτησης';
        return;
      }

      if (this.typeOfReport == 'ACCOUNTS') {
        await this.getHistoryOfAccounts();
      } else if (this.typeOfReport == 'STATS') {
        this.selectedCategory = 0;
        await this.getProductSales();
      }

    },

    async getHistoryOfAccounts() {
      this.searching = true;
      let restaurantIds = this.selectedDepartmentId == null ? this.restaurantsWithTableOpenings.map(restaurant => restaurant.id) : this.selectedDepartmentId;

      let startingDateForServer = this.formatDateForServer(this.startingDate);
      let endingDateForServer = this.formatDateForServer(this.endingDate);

      let availableTables = this.getTablesToSearch();
      let tableIds = availableTables.map(table => table.id);

      let userIds = [];
      if (this.waiterToSearch != null) {
        userIds.push(this.waiterToSearch);
      }

      let apiResponse = await Promise.all([StoreApi.getPricesForClosedTables(restaurantIds, tableIds, userIds, startingDateForServer, endingDateForServer, this.authToken, this.jwt), StoreApi.getTotalAmountOfClosedTables(restaurantIds, tableIds, userIds, startingDateForServer, endingDateForServer, this.authToken, this.jwt)]).then(data => {
        return {
          prices: data[0],
          totalAmountOfClosed: data[1]
        }
      });

      this.pricesOfClosed = apiResponse.prices;
      this.totalAmountOfClosed = apiResponse.totalAmountOfClosed;
      this.searching = false;
    },

    async getProductSales() {
      this.searching = true;

      let startingDateForServer = this.formatDateForServer(this.startingDate);
      let endingDateForServer = this.formatDateForServer(this.endingDate);

      let response = await StoreApi.getProductSales(this.selectedDepartmentId, startingDateForServer, endingDateForServer, this.authToken, this.jwt);

      this.itemStats = response;

      this.searching = false;
    },

    setTitle() {
      try {
        document.title = this.title;
      } catch (error) {
        console.log("Could not set title");
      }
    }
  },
};
</script>

<style scoped>
.margin-top-0 {
  margin-top: 0px !important;
}

.margin-top-16 {
  margin-top: 16px !important;
}

table {
  border-collapse: collapse;
}

th {
  border: 1px solid #dddddd;
  padding: 8px;
  color: #003340;
  font-size: 15px;
  white-space: nowrap;
}

td {
  border: 1px solid #dddddd;
  padding: 8px;
  font-size: 14px;
}

.product-header {
  text-align: left;
  max-width: 170px;
}

.text-center {
  text-align: center;
}

#history-screen {
  margin: 0px;
  box-sizing: border-box;
  padding: 0px;
  overflow-x: hidden;
  background: #fcfcfc;
  min-height: 100vh;
  z-index: 0;
  overflow-x: hidden !important;
}

#banner {
  position: relative;
  width: 100vw;
  height: 55vh;
  min-height: 220px;
}

#banner-image {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#actions-buttons-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 8px;
  margin-top: 10px;
}

#search-history-btn {
  color: #ffffff;
  background-color: #198b4a;
  border-radius: 6px;
  padding: 4px 14px 4px 14px;
  font-size: 15px;
}

#store-info {
  position: absolute;
  top: 15%;
  right: 5%;
  width: 30%;
  min-width: 400px;
  background: white;
  border-radius: 18px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.36), 2px 2px 4px rgba(0, 0, 0, 0.48);
}

#store {
  cursor: unset;
}

#error-message {
  margin-left: 10px;
  margin-top: 4px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
  color: #d9534f;
}

#history-results-container {
  min-height: 600px !important;
}

#type-of-reports-container {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  scrollbar-width: none;
  margin-top: 4px;
  margin-bottom: 8px;
  margin-left: 8px;
}

.report-type-selection-button {
  padding: 5px 5px 5px 5px;
  border-radius: 4px;
  cursor: pointer;
  color: #0d6efd;
  background-color: #ffffff;
  border: 1px solid #0d6efd;
  font-size: 13px;
  font-weight: 600;
  margin-top: 0px;
  margin-right: 6px;
  white-space: nowrap;
}

.selected-report-type {
  color: #ffffff !important;
  background-color: #0d6efd !important;
}

#general-errors-container {
  min-height: 600px !important;
  padding-top: 10px;
  padding-left: 8px;
  padding-right: 8px;
}

.info-message {
  font-size: 15px;
  font-weight: 600;
  color: #003340;
}

#prices-results-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
}

#receipts-results-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 10px;
  margin-top: 10px;
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
  padding-bottom: 10px;
}

#statistics-results-container {
}

#select-filter-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 1vh;
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
  padding: 7.5px 0 7.5px 0;
  color: black;
}

.label-and-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  margin-top: 8px;
  margin-left: 8px;
}

.filter-title {
  font-size: 15px;
  font-weight: 700;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

select {
  display: flex;
  align-self: center;
  justify-self: center;
  -webkit-appearance: auto !important;
  box-shadow: inset 0px 0px 2px rgba(116, 135, 150, 0.2),
    inset 1px 1px 2px rgba(116, 135, 150, 0.36);
  border-radius: 6px;
  margin-bottom: 0.5rem;
  margin-left: 7px;
  padding: 2px;
  color: #198b4a !important;
  font-weight: bold;
  background: #f7f7f7;
}

#amount-of-closed {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}

.total-price-of-closed {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.payment-method-price {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 4px;
}

.receipts-separator {
  border-color: #d8d8d8;
  background-color: #d8d8d8;
}
@media screen and (max-width: 800px) {
  select {
    font-size: 14.5px;
  }
}
@media screen and (max-width: 300px) {
  select {
    font-size: 12px;
  }
}

option {
  color: #198b4a !important;
  font-weight: 600;
}

#upper-bar {
  position: absolute;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;

  padding-right: 1%;
}

#profile-and-cart {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
}

#language-select {
  position: absolute;
  right: 200px;
  top: 8px;
  cursor: pointer;
}

.language-position-in-login {
  right: 159px !important;
  z-index: 202;
}

#enter-prompt {
  position: absolute;
  top: 8px;
  right: 88px !important;
  z-index: 201;
}

#profile-dropdown {
  position: absolute;
  top: 0px;
  right: 88px !important;
  z-index: 201;
}

#back-to-services {
  position: fixed;
  left: 80px;
  box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.32), 3px 4px 8px rgba(0, 0, 0, 0.46);
}

#logo-container {
  background: white !important;
  /* height: 45px; */
  margin-left: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-content: center;
  padding: 0px 16px 0px 12px;
  border-radius: 12px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
}

#logo {
  cursor: pointer;
  height: 40px;
  width: auto;
}

#link-to-landing {
  text-decoration: none;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 204;
  opacity: 1;
  transition: 0.67s;
}

.hide {
  visibility: hidden;
  display: none;
}

#loading-mask {
  position: fixed;
  top: 0;
}

@media screen and (max-width: 1018px) {
  #language-select {
    left: unset;
    top: 5px !important;
    right: 20px;
    cursor: pointer;
  }

  .language-position-in-login {
    right: 20px !important;
    z-index: 202;
  }

  .no-bottom-margin {
    margin-bottom: 0px !important;
  }

  #profile-dropdown {
    right: 20px !important;
  }

  .profile-dropdown-with-lang-select {
    top: 58px !important;
  }

  .profile-dropdown-without-lang-select {
    top: 2px !important;
  }

  #enter-prompt {
    right: 20px !important;
    margin: 0;
  }

  .enter-prompt-with-lang-select {
    top: 56px !important;
  }

  .enter-prompt-without-lang-select {
    top: 5px !important;
  }

  #back-to-services {
    left: 20px;
  }

  #logo-container {
    min-width: 95px !important;
    justify-content: flex-start;
    margin-left: 20px;
  }

  #logo {
    height: 33px;
  }

  #banner {
    height: 40vh;
  }

  #store-info {
    top: 165px;
    width: 90%;
    left: 5%;
    min-width: 200px;
    max-height: 112px;
  }

  #upper-bar {
    top: 15px;
    height: unset;
  }
}

@media screen and (max-width: 350px) {
  #store-info {
    top: 140px;
  }

  th {
    font-size: 14px;
  }

  td {
    font-size: 13px;
  }

  .product-header {
    max-width: 145px;
  }
}

@media screen and (max-width: 1018px) {
  #banner {
    height: 290px !important;
  }
}

@media screen and (max-width: 530px) {
  #banner {
    height: 280px !important;
  }
}

@media screen and (max-width: 350px) {
  #banner {
    height: 250px !important;
  }
}

@media screen and (max-width: 330px) {
  #banner {
    height: 235px !important;
  }
}

@media screen and (max-width: 285px) {
  th {
    font-size: 13px;
  }

  td {
    font-size: 12px;
  }

  .product-header {
    max-width: 120px;
  }
}
</style>
